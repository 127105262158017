export default [
  {
    path: '/',
    redirect: '/car'
  },
  {
    path: '/car',
    name: 'car',
    meta: {
      title: '车辆报表'
    },
    component: () => import('@/views/car/index')
  },
  {
    path: '/face',
    name: 'face',
    redirect: 'ranking',
    component: () => import('@/layout'),
    children: [
      {
        path: '/ranking',
        name: 'ranking',
        meta: {
          title: '客流排行'
        },
        component: () => import('@/views/face/ranking')
      },
      {
        path: '/trend',
        name: 'trend',
        meta: {
          title: '客流趋势分析'
        },
        component: () => import('@/views/face/trend')
      },
      {
        path: '/compare',
        name: 'compare',
        meta: {
          title: '客流同环比分析'
        },
        component: () => import('@/views/face/compare')
      },
      {
        path: '/group',
        name: 'group',
        meta: {
          title: '客群分析'
        },
        component: () => import('@/views/face/group')
      },
    ]
  },
]