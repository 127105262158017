import Vue from "vue"
import {
  TimePicker,
  Popover,
  ButtonGroup,
  Button,
  Progress,
  Drawer,
  Menu,
  MenuItem,
  Row,
  Col,
  Select,
  RadioGroup,
  RadioButton,
  DatePicker,
  Option,
  Form,
  FormItem,
  Input,
  Tree,
} from "element-ui"
import 'element-ui/lib/theme-chalk/index.css'

const components = [
  TimePicker,
  Popover,
  ButtonGroup,
  Button,
  Progress,
  Drawer,
  Menu,
  MenuItem,
  Row,
  Col,
  Select,
  RadioGroup,
  RadioButton,
  DatePicker,
  Option,
  Form,
  FormItem,
  Input,
  Tree,
]

components.forEach(component => Vue.use(component))
